import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const FAQsPage: React.FC = () => (
    <Layout>
        <SEO title="Frequently Asked Questions" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Frequently Asked Questions</h1>

                <h2 className="title is-3">
                    Do I need any experience with technology?
                </h2>

                <p>
                    All levels of experience are welcome, including complete
                    beginners.
                </p>

                <h2 className="title is-3">Why tech?</h2>

                <p>
                    According to one{" "}
                    <a href="http://www.syracuse.com/news/index.ssf/2013/11/experts_expect_more_employees_with_aspergers_in_the_workplace.html">
                        source
                    </a>
                    , "
                    <strong>
                        the unemployment rate for people with Asperger's is as
                        high as 85 percent
                    </strong>
                    ", and we intend to target that problem.
                </p>

                <p>
                    There is a large tech scene in the Bay Area, and many people
                    on the autism spectrum are able to find success with
                    technology, so that is the area that we're focusing on.
                </p>

                <p>
                    There also may be some connection between technical fields
                    and autism:
                </p>

                <blockquote>
                    <p>
                        A survey of more than 450,000 people in the UK has shown
                        there is a significant{" "}
                        <a href="https://www.cam.ac.uk/research/news/study-of-half-a-million-people-reveals-sex-and-job-predict-how-many-autistic-traits-you-have">
                            correlation between a higher score on the Autism
                            Quotient and being a scientist or engineer
                        </a>
                        .
                    </p>
                    <p>
                        AQ scores are also higher for men than for women.
                        &quot;On average, the male AQ score was 21.6, compared
                        to a female score of 19.0.
                    </p>
                    <p>
                        People work in a STEM-related job had{" "}
                        <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0141229">
                            an average AQ score of 21.9 compared to a score of
                            18.9
                        </a>{" "}
                        for individuals working in non-STEM jobs.
                    </p>
                    <p>
                        This suggests autistic traits are linked to both sex and
                        to having a ‘systems-thinking’ mind.&quot; A professor
                        involved with the work said, &quot;These may shed light
                        on why we find males in the population on average have
                        slightly more autistic traits than females do, and why
                        fathers and grandfathers of children with autism are
                        over-represented in STEM fields.&quot; [
                        <a href="http://science.slashdot.org/story/15/11/04/0513208/huge-survey-shows-correlation-between-autistic-traits-and-stem-jobs">
                            source
                        </a>
                        ]
                    </p>
                </blockquote>

                <h2 className="title is-3">
                    I'm not technical, but I'm a tech entrepreneur with
                    autism/Asperger's &mdash; can I still join?
                </h2>

                <p>Yes, everyone is welcome.</p>

                <h2 className="title is-3">
                    Are there good resources for learning how to program?
                </h2>
                <p>
                    In the San Francisco Bay Area, you can join{" "}
                    <a href="https://codeselfstudy.com/">Code Self Study</a>.
                    See also the <Link to="/resources">resources page</Link> and
                    the <Link to="/learn/">learn how to code page</Link>.
                </p>

                <h2 className="title is-3">
                    I am the parent of a child with autism or Asperger's &mdash;
                    can I join?
                </h2>
                <p>Yes.</p>

                <h2 className="title is-3">
                    Do I need an official autism or Asperger's diagnosis to
                    join?
                </h2>
                <p>
                    An official diagnosis is not required. This is an inclusive
                    and friendly group for people who are interested in helping
                    each other.
                </p>

                <h2 className="title is-3">
                    Are you only in Berkeley, California?
                </h2>

                <p>
                    Our meetings are focused in Berkeley, which is where we plan
                    to open our{" "}
                    <Link to="/educational-center/">educational center</Link>.
                    Anyone is welcome to join our online community.{" "}
                    <Link to="/join/">Join our group</Link> and{" "}
                    <Link to="/newsletter/">subscribe to the newsletter</Link>{" "}
                    to register your support and interest.
                </p>

                <h2 className="title is-3">Can I register with a pseudonym?</h2>

                <p>Yes, anonymity is fine.</p>

                <h2 className="title is-3">Can I sponsor your group?</h2>

                <p>
                    Yes. Any assistance with organization, meeting space,
                    software, hardware, partnerships, job training, and/or other
                    help would be greatly appreciated. Please see the{" "}
                    <Link to="/support-us/">support page</Link> and/or{" "}
                    <Link to="/contact/">contact us</Link>.
                </p>

                <p>
                    We are especially looking for assistance with opening up a{" "}
                    <Link to="/educational-center">
                        self-directed educational center for programmers
                    </Link>
                    .
                </p>

                <h2 className="title is-3">
                    Where can I send additional questions and feedback about the
                    group?
                </h2>

                <p>
                    <Link to="/contact/">Click here</Link> to send us an email.
                </p>
            </div>
        </section>
    </Layout>
);

export default FAQsPage;
